
























import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class InfoFillingModal extends Vue {
  @Prop()
  props: any;

  cancel() {
    this.$emit("close-modal");
  }

  discard() {
    this.$emit("close-modal");
    this.props.cancel();
    setTimeout(() => {
      this.props.next();
    }, 400);
  }

  save() {
    this.$emit("close-modal");
    this.props.save();
    if (this.props.isPreDataFilled || this.props.isDataFilled) {
      setTimeout(() => {
        this.props.next();
      }, 400);
    }
  }
}
